<template>
  <div class="p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-start items-center gap-1">
        <svg
          @click="router.go(-1)"
          class="h-8 w-8 text-blue-600 hover:text-blue-700 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold ">Send PDF Quote</h1>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <form v-else class="max-w-3xl space-y-5" @submit.prevent="sendPDF">
        <div>
          <label
            for="email_from"
            class="block text-sm font-medium text-gray-600"
          >
            From
          </label>
          <input
            type="text"
            v-model="business.settings_default_email_from"
            disabled
            name="email_from"
            class="w-full px-4 py-2 rounded"
          />
        </div>

        <div>
          <label for="email_to" class="block text-sm font-medium text-gray-600">
            To
          </label>
          <input
            type="text"
            v-model="quote.customer.customer_email"
            name="email_to"
            disabled
            class="w-full px-4 py-2 rounded"
          />
        </div>

        <div>
          <label for="email_cc" class="block text-sm font-medium text-gray-600">
            CC
          </label>
          <div class="flex justify-between">
            <input
              @change="checkEmails()"
              type="text"
              v-model.trim="cc"
              name="email_cc"
              class="w-full px-4 py-2 rounded"
            />
          </div>
        </div>

        <div>
          <label
            for="attachment"
            class="block text-sm font-medium text-gray-600"
            >PDF</label
          >
          <div class="relative">
            <input
              type="text"
              :value="`Quote_${quote.sequence_number}.pdf`"
              name="attachment"
              disabled
              class="w-full pl-10 pr-4 py-2 rounded"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 absolute top-2 left-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          </div>
        </div>

        <div>
          <label
            for="email_content"
            class="block text-sm font-medium text-gray-600"
          >
            Content
          </label>
          <textarea
            v-model="business.settings_default_email_content"
            name="email_content"
            class="mt-1 w-full rounded px-4 py-2"
            rows="8"
          ></textarea>
        </div>

        <div class="pt-2 flex justify-center">
          <button
            type="submit"
            class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
          >
            <div v-if="isPending" class="spinner"></div>
            Send
          </button>
        </div>

        <div
          v-if="error || emailError || email_err"
          class="text-center text-red-600 font-semibold"
        >
          {{ error }} {{ emailError }} {{ email_err }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { functions, timestamp } from "@/firebaseConfig";
import getDocument from "@/composables/getDocument";
import useCollection from "@/composables/useCollection";

export default {
  props: ["quote_id", "user"],

  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const parent = route.path.substring(0, route.path.lastIndexOf("/"));

    const isLoading = ref(true);
    const isPending = ref(false);
    const emailsHasError = ref(false);
    const cc = ref("");
    const emailError = ref("");

    const recipient = ref([]);

    const business_id = props.user.claims.business_id ?? props.id;

    const { error, document: quote } = getDocument(
      `businesses/${business_id}/quotes`,
      props.quote_id
    );

    const { error: business_error, document: business } = getDocument(
      `businesses`,
      business_id
    );

    const { error: email_err, addDoc: email_addDoc } = useCollection(
      `businesses/${business_id}/emails`
    );

    watch(quote, () => {
      if (quote.value && !quote.value.quote_status) {
        router.push(parent);
      }
      if (quote.value) {
        isLoading.value = false;
      }
    });

    const sendPDF = async () => {
      if (emailsHasError.value) return;
      isPending.value = true;
      try {
        const sendingEmail = functions.httpsCallable("quote_sendMail");
        const imagePath = business.value.business_logo.split("/");
        const data = {
          from: business.value.settings_default_email_from,
          to: quote.value.customer.customer_email,
          cc: cc.value.trim(),
          body: business.value.settings_default_email_content,
          sequenceNumber: quote.value.sequence_number,
          businessLogo: business.value.business_logo,
          businessId: business_id,
          quoteId: props.quote_id,
          businessLogoName: imagePath[imagePath.length - 1],
          mailgun_api_key: business.value.mailgun_api_key,
          mailgun_domain: business.value.mailgun_domain,
        };
        const sendHasErrorEmail = await sendingEmail(data);

        if (!sendHasErrorEmail.data) {
          email_addDoc({
            createdAt: timestamp(),
            createdBy: props.user.uid,
            email_type: "send_pdf",
            email_from: business.value?.settings_default_email_from,
            email_to: quote.value.customer.customer_email,
            email_cc: cc.value.trim() ?? "",

            email_content: business.value.settings_default_email_content,
            email_attachment: `${business_id}/pdfs/Quote_${quote.value.sequence_number}`,
          });
        }

        isPending.value = false;
        router.push(parent);
      } catch (e) {
        isPending.value = false;
        return e;
      }
    };

    const checkEmails = () => {
      if (!cc.value) {
        emailError.value = "";
        return;
      }
      const emails = cc.value.split(",");
      emailsHasError.value = false;
      emailError.value = "";
      emails.map((email) => {
        if (!validateEmail(email.trim())) {
          emailsHasError.value = true;
          emailError.value =
            "Please enter valid email address for the CC field.";
        }
      });
    };

    const validateEmail = (email) => {
      const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(email);
    };

    return {
      router,
      error,
      cc,
      isLoading,
      isPending,
      business,
      emailError,
      sendPDF,
      checkEmails,
      recipient,
      business_error,
      emailsHasError,
      email_err,
      quote,
    };
  },
};
</script>
